// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

$dark: #2056ae;
$navbar-dark-color: rgba(255,255,255,0.75);
$navbar-dark-hover-color: rgba(255,255,255, .85);

$biggesang-sekundaer: #00adef;

$h1-font-size: 21px;
$h2-font-size: 18px;

$navbar-nav-link-padding-x: .75rem;

//$hr-border-color: $biggesang-sekundaer;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
//@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";

//
// Custom styles
//

.navbar .nav-link {
  text-transform: uppercase;
}

//header.temp {
//  border-bottom: 1px solid #ddd;
//  background-color: white;
//}

.navbar .nav-item .nav-link.icon {
  padding-top: 5px;
}

.intro {
  font-size: 19px;
  text-align: center;
}

.teaser-image {
  max-width: 100%;
}

section p:last-child {
  margin-bottom: 0;
}

.keep-pt {
  display: none;
}

.container img {
  max-width: 100%;
  height: auto;
}

@media only screen and (min-width: 992px) {
  footer .navbar .navbar-nav .nav-item .nav-link {
    padding: 0 0.7em;
  }
  footer .navbar .navbar-nav .nav-item:not(:last-child) .nav-link {
    border-right: 1px solid rgba(255,255,255,0.85);
  }

  .navbar-nav .nav-link {
    font-size: 20px;
  }
}

.gallery > div > div.row {
  margin-bottom: 15px;
}

.date-col {
  width: 150px;
}

// Ekko lightbox
.ekko-lightbox {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;

  &-container {
    position:relative;
    > div.ekko-lightbox-item {
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      width:100%;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &-nav-overlay {
    z-index:100;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display: flex;

    a {
      flex: 1;
      display:flex;
      align-items: center;

      opacity: 0;
      transition: opacity 0.5s;
      color: #fff;
      font-size:30px;
      z-index:100;

      > * {
        flex-grow:1;
        &:focus {
          outline: none;
        }
      }
      span {
        padding:0 30px;
      }
      &:last-child span {
        text-align: right;
      }
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
      &.disabled {
        cursor: default;
        visibility: hidden;
      }
    }
  }

  a:hover {
    opacity: 1;
    text-decoration: none;
  }
  .modal-dialog {
    display:none;
  }
  .modal-footer {
    text-align: left;
  }

  // http://tobiasahlin.com/spinkit/
  &-loader {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    width:100%;

    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;

    > div {
      width: 40px;
      height: 40px;

      position: relative;
      text-align: center;

      > div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        animation: sk-bounce 2.0s infinite ease-in-out;
        &:last-child {
          animation-delay: -1.0s;
        }
      }
    }
  }

  .modal-dialog &-loader {
    > div > div {
      background-color:#333;
    }
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
  }
}

.file-tree {
  margin: 0 auto;
  background: #fff;
}

.file-tree ul {
  list-style-type: none;
  padding-left: 25px;
  margin: 0;
}

.file-tree .folder {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.file-tree .folder:hover {
  color: #007BFF;
}

.file-tree .hidden {
  display: none;
}

.file-tree li {
  margin: 5px 0;
  font-size: 17px;
}

.file-tree span::before {
  content: " ";
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
}

.file-tree .folder::before {
  content: "📁";
}

.file-tree li:not(.folder)::before {
  content: "📄";
}
